var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.type === "list"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-list",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("line", { attrs: { x1: "8", y1: "6", x2: "21", y2: "6" } }),
            _c("line", { attrs: { x1: "8", y1: "12", x2: "21", y2: "12" } }),
            _c("line", { attrs: { x1: "8", y1: "18", x2: "21", y2: "18" } }),
            _c("line", { attrs: { x1: "3", y1: "6", x2: "3", y2: "6" } }),
            _c("line", { attrs: { x1: "3", y1: "12", x2: "3", y2: "12" } }),
            _c("line", { attrs: { x1: "3", y1: "18", x2: "3", y2: "18" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "user"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-user",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: { d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" },
            }),
            _c("circle", { attrs: { cx: "12", cy: "7", r: "4" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "chevron-up"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-chevron-up",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [_c("polyline", { attrs: { points: "18 15 12 9 6 15" } })]
        )
      : _vm._e(),
    _vm.type === "check-circle"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-check-circle",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", { attrs: { d: "M22 11.08V12a10 10 0 1 1-5.93-9.14" } }),
            _c("polyline", { attrs: { points: "22 4 12 14.01 9 11.01" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "circle"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-circle",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [_c("circle", { attrs: { cx: "12", cy: "12", r: "10" } })]
        )
      : _vm._e(),
    _vm.type === "mail"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-mail mr-1",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z",
              },
            }),
            _c("polyline", { attrs: { points: "22,6 12,13 2,6" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "phone"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-phone mr-1",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79\n      0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45\n      2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "message-square"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-message-square",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "user-x"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-user-x",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: { d: "M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" },
            }),
            _c("circle", { attrs: { cx: "8.5", cy: "7", r: "4" } }),
            _c("line", { attrs: { x1: "18", y1: "8", x2: "23", y2: "13" } }),
            _c("line", { attrs: { x1: "23", y1: "8", x2: "18", y2: "13" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "at-sign"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-at-sign",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", { attrs: { cx: "12", cy: "12", r: "4" } }),
            _c("path", {
              attrs: { d: "M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "chevron-right"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-chevron-right",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [_c("polyline", { attrs: { points: "9 18 15 12 9 6" } })]
        )
      : _vm._e(),
    _vm.type === "lock"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-lock",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("rect", {
              attrs: {
                x: "3",
                y: "11",
                width: "18",
                height: "11",
                rx: "2",
                ry: "2",
              },
            }),
            _c("path", { attrs: { d: "M7 11V7a5 5 0 0 1 10 0v4" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "upload"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-upload",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: { d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" },
            }),
            _c("polyline", { attrs: { points: "17 8 12 3 7 8" } }),
            _c("line", { attrs: { x1: "12", y1: "3", x2: "12", y2: "15" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "activity"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("polyline", {
              attrs: { points: "22 12 18 12 15 21 9 3 6 12 2 12" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "clipboard"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2",
              },
            }),
            _c("rect", {
              attrs: {
                x: "8",
                y: "2",
                width: "8",
                height: "4",
                rx: "1",
                ry: "1",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "credit-card"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("rect", {
              attrs: {
                x: "1",
                y: "4",
                width: "22",
                height: "16",
                rx: "2",
                ry: "2",
              },
            }),
            _c("line", { attrs: { x1: "1", y1: "10", x2: "23", y2: "10" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "search"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", { attrs: { cx: "11", cy: "11", r: "8" } }),
            _c("line", {
              attrs: { x1: "21", y1: "21", x2: "16.65", y2: "16.65" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "alert-octagon"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("polygon", {
              attrs: {
                points:
                  "7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2",
              },
            }),
            _c("line", { attrs: { x1: "12", y1: "8", x2: "12", y2: "12" } }),
            _c("line", { attrs: { x1: "12", y1: "16", x2: "12", y2: "16" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "alert-triangle"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z",
              },
            }),
            _c("line", { attrs: { x1: "12", y1: "9", x2: "12", y2: "13" } }),
            _c("line", { attrs: { x1: "12", y1: "17", x2: "12", y2: "17" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "bell"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "chevron-down"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [_c("polyline", { attrs: { points: "6 9 12 15 18 9" } })]
        )
      : _vm._e(),
    _vm.type === "dollar-sign"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("line", { attrs: { x1: "12", y1: "1", x2: "12", y2: "23" } }),
            _c("path", {
              attrs: { d: "M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "download"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: { d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" },
            }),
            _c("polyline", { attrs: { points: "7 10 12 15 17 10" } }),
            _c("line", { attrs: { x1: "12", y1: "15", x2: "12", y2: "3" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "file-text"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth || 2,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z",
              },
            }),
            _c("polyline", { attrs: { points: "14 2 14 8 20 8" } }),
            _c("line", { attrs: { x1: "16", y1: "13", x2: "8", y2: "13" } }),
            _c("line", { attrs: { x1: "16", y1: "17", x2: "8", y2: "17" } }),
            _c("polyline", { attrs: { points: "10 9 9 9 8 9" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "globe"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
            _c("line", { attrs: { x1: "2", y1: "12", x2: "22", y2: "12" } }),
            _c("path", {
              attrs: {
                d: "M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "layout"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("rect", {
              attrs: {
                x: "3",
                y: "3",
                width: "18",
                height: "18",
                rx: "2",
                ry: "2",
              },
            }),
            _c("line", { attrs: { x1: "3", y1: "9", x2: "21", y2: "9" } }),
            _c("line", { attrs: { x1: "9", y1: "21", x2: "9", y2: "9" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "minus"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [_c("line", { attrs: { x1: "5", y1: "12", x2: "19", y2: "12" } })]
        )
      : _vm._e(),
    _vm.type === "plus-circle"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
            _c("line", { attrs: { x1: "12", y1: "8", x2: "12", y2: "16" } }),
            _c("line", { attrs: { x1: "8", y1: "12", x2: "16", y2: "12" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "save"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z",
              },
            }),
            _c("polyline", { attrs: { points: "17 21 17 13 7 13 7 21" } }),
            _c("polyline", { attrs: { points: "7 3 7 8 15 8" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "shield"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: { d: "M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "shopping-cart"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", { attrs: { cx: "9", cy: "21", r: "1" } }),
            _c("circle", { attrs: { cx: "20", cy: "21", r: "1" } }),
            _c("path", {
              attrs: {
                d: "M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "x"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("line", { attrs: { x1: "18", y1: "6", x2: "6", y2: "18" } }),
            _c("line", { attrs: { x1: "6", y1: "6", x2: "18", y2: "18" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "plus"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("line", { attrs: { x1: "12", y1: "5", x2: "12", y2: "19" } }),
            _c("line", { attrs: { x1: "5", y1: "12", x2: "19", y2: "12" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "tag"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-tag mr-1",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z",
              },
            }),
            _c("line", { attrs: { x1: "7", y1: "7", x2: "7", y2: "7" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "chevron-left"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-chevron-left",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [_c("polyline", { attrs: { points: "15 18 9 12 15 6" } })]
        )
      : _vm._e(),
    _vm.type === "printer"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-printer",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("polyline", { attrs: { points: "6 9 6 2 18 2 18 9" } }),
            _c("path", {
              attrs: {
                d: "M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2",
              },
            }),
            _c("rect", {
              attrs: { x: "6", y: "14", width: "12", height: "8" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "help-circle"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-help-circle",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": "2.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", {
              staticStyle: { "stroke-width": "2" },
              attrs: { cx: "12", cy: "12", r: "10" },
            }),
            _c("path", {
              attrs: { d: "M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" },
            }),
            _c("line", {
              attrs: { x1: "12", y1: "17", x2: "12.01", y2: "17" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "alert-circle"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-alert-circle",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
            _c("line", { attrs: { x1: "12", y1: "8", x2: "12", y2: "12" } }),
            _c("line", {
              attrs: { x1: "12", y1: "16", x2: "12.01", y2: "16" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "book-open"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-book-open",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: { d: "M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" },
            }),
            _c("path", {
              attrs: { d: "M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "edit"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-edit",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7",
              },
            }),
            _c("path", {
              attrs: {
                d: "M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "pencil"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-pencil",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 512 512",
              stroke: _vm.stroke,
              fill: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3\n    11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5\n    8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8\n    37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3\n     6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16\n     16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1\n     62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3\n      18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6\n      0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "trash"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-trash",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 448 512",
              width: _vm.width,
              height: _vm.height,
              stroke: _vm.stroke,
              fill: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8\n    28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7\n    0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64\n    64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2\n     16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16\n     16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8\n      0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "upload-cloud"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-upload-cloud",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("polyline", { attrs: { points: "16 16 12 12 8 16" } }),
            _c("line", { attrs: { x1: "12", y1: "12", x2: "12", y2: "21" } }),
            _c("path", {
              attrs: {
                d: "M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3",
              },
            }),
            _c("polyline", { attrs: { points: "16 16 12 12 8 16" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "download-cloud"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-download-cloud",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("polyline", { attrs: { points: "8 17 12 21 16 17" } }),
            _c("line", { attrs: { x1: "12", y1: "12", x2: "12", y2: "21" } }),
            _c("path", {
              attrs: {
                d: "M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "minus-circle"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-minus-circle",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
            _c("line", { attrs: { x1: "8", y1: "12", x2: "16", y2: "12" } }),
          ]
        )
      : _vm._e(),
    _vm.type === "refresh-ccw"
      ? _c(
          "svg",
          {
            staticClass: "feather feather-refresh-ccw",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          },
          [
            _c("polyline", { attrs: { points: "1 4 1 10 7 10" } }),
            _c("polyline", { attrs: { points: "23 20 23 14 17 14" } }),
            _c("path", {
              attrs: {
                d: "M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "house"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.height,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              viewBox: "0 0 24 24",
              fill: _vm.fill,
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M3.09277 9.55005L12.0928 2.55005L21.0928 9.55005V20.55C21.0928 21.0805 20.8821 21.5892\n          20.507 21.9643C20.1319 22.3393 19.6232 22.55 19.0928 22.55H5.09277C4.56234 22.55 4.05363\n          22.3393 3.67856 21.9643C3.30349 21.5892 3.09277 21.0805 3.09277 20.55V9.55005Z",
                stroke: "black",
                "stroke-width": _vm.strokeWidth,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
            _c("path", {
              attrs: {
                d: "M9.09277 22.55V12.55H15.0928V22.55",
                stroke: "black",
                "stroke-width": _vm.strokeWidth,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.type === "briefcase"
      ? _c(
          "svg",
          {
            attrs: {
              width: _vm.width,
              height: _vm.height,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              fill: _vm.fill,
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  stroke: _vm.stroke,
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": _vm.strokeWidth,
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "m20 7h-16c-1.10457 0-2 .89543-2 2v10c0 1.1046.89543 2 2 2h16c1.1046 0 2-.8954\n        2-2v-10c0-1.10457-.8954-2-2-2z",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "m16 21v-16c0-.53043-.2107-1.03914-.5858-1.41421-.3751-.37508-.8838-.58579-1.4142-.58579h-4c-.53043\n        0-1.03914.21071-1.41421.58579-.37508.37507-.58579.88378-.58579 1.41421v16",
                  },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "users"
      ? _c(
          "svg",
          {
            attrs: {
              width: _vm.width,
              height: _vm.height,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
              fill: _vm.fill,
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  stroke: _vm.stroke,
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": _vm.strokeWidth,
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "m17 21v-2c0-1.0609-.4214-2.0783-1.1716-2.8284-.7501-.7502-1.7675-1.1716-2.8284-1.1716h-8c-1.06087\n         0-2.07828.4214-2.82843 1.1716-.75014.7501-1.17157 1.7675-1.17157 2.8284v2",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "m9 11c2.2091 0 4-1.79086 4-4s-1.7909-4-4-4c-2.20914 0-4 1.79086-4 4s1.79086 4 4 4z",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "m23 21v-2c-.0007-.8863-.2956-1.7472-.8386-2.4477-.543-.7004-1.3033-1.2007-2.1614-1.4223",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "m16 3.13c.8604.22031 1.623.72071 2.1676 1.42232.5446.7016.8402 1.56451.8402 2.45268 0\n        .88818-.2956 1.75108-.8402 2.45269s-1.3072 1.20201-2.1676 1.42231",
                  },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }