import * as t from '../mutations'
import http from '@/http'
import { isCreditCardOrder, isFilingItem, determineObjectType }from '@/components/SchemaForm/helper.js'
import Vue from 'vue'

const ALLOWED_OBJECT_TYPES = ['order-items', 'services', 'shopping-cart-filing']

const getDefaultState = () => {
  return {
    currentObject: null,
    currentObjectType: null,
    schema: null,
    schemaLoaded: false,
    useWrapperValidations: false,
    wrapperValidationObservers: {},
    wrapperValid: false,
    showWrapperValidationErrors: false,
    showAddressPrivacyBorder: true,
  }
}
const STATE = getDefaultState()

const GETTERS = {
  currentObject: state => state.currentObject,
  currentObjectType: state => state.currentObjectType,
  currentProductKind: state => state.currentObject?.product?.kind,
  schema: state => state.schema,
  company: (_state, getters) => getters.currentObject?.company,
  useWrapperValidations: state => state.useWrapperValidations,
  wrapperValidationObservers: state => state.wrapperValidationObservers,
  invalidSchemaFields: (_state, getters) => {
    return Object.entries(getters.wrapperValidationObservers).filter(entry => !entry[1]).map(entry => entry[0])
  },
  validationErrorsForSchemaKey: (_state, getters) => schemaKey => {
    const schemaKeys = schemaKey.split('/').filter(x => x)
    let result
    function getValidationErrorByKey(fields, keys) {
      const res = fields.find(el => el.name === keys[0])
      if (keys.length === 1) result = Object.values(res.validations)
      else {
        keys.splice(0, 1)
        getValidationErrorByKey(res.fields, keys)
      }
    }
    getValidationErrorByKey(getters.schema, schemaKeys)
    return result
  },
  wrapperValid: getters => Object.values(getters.wrapperValidationObservers).every(observer => observer),
  showWrapperValidationErrors: state => state.showWrapperValidationErrors,
  showAddressPrivacyBorder: state => state.showAddressPrivacyBorder,
}

const ACTIONS = {
  async loadObject({ commit }, { object_type, object_id }) {
    if (ALLOWED_OBJECT_TYPES.includes(object_type)) {
      const response = await http.get(`client/${object_type.replace(/-/g, '_')}/${object_id}`)
      commit(t.SET_SCHEMA_OBJECT_TYPE, object_type)
      commit(t.SET_SCHEMA_OBJECT, response.data.result)
      return response.data.result
    }
  },

  async setObject({ commit }, { object }) {
    const object_type = determineObjectType(object)
    if (ALLOWED_OBJECT_TYPES.includes(object_type)) {
      commit(t.SET_SCHEMA_OBJECT_TYPE, object_type)
      commit(t.SET_SCHEMA_OBJECT, object)
    }
  },

  async reloadObject({ commit, getters }) {
    const response = await http.get(`client/${getters.currentObjectType}/${getters.currentObject.id}`)
    commit(t.SET_SCHEMA_OBJECT, response.data.result)
  },

  async loadSchema({ commit, getters }, source) {
    if (getters.currentObject === null) {
      return false
    }

    let res = {}

    if (isCreditCardOrder(getters.currentObject)) {
      res = await http.get(`credit_card_processing/${getters.currentObject.product.id}/schema`, {
        params: {
            company_id: getters.company.id,
            source: source,
        },
      })
    } else if (isFilingItem(getters.currentObject)) {
      res = await http.get(`filing_methods/${getters.currentObject.product.filing_method.id}/schema`, {
        params: {
          company_id: getters.company.id,
          record_id: getters.currentObject.id,
          source: source,
        },
      })
    } else {
      res = await http.get(`client/services/schema`, {
        params: {
          id: getters.currentObject.id,
          source: source,
        },
      })
    }

    let schema = res.data.result.schema

    if (Array.isArray(schema)) {
      schema = schema.reduce((acc, step) => acc.concat(step.fields), [])
    }

    commit(t.SET_SCHEMA, schema)
  },

  setSchema({ commit }, schema) {
    commit(t.SET_SCHEMA, schema)
  },

  async loadFilingProductSchemaByFilingMethod({ commit, getters }, { filingMethodId, pdfResourceId = null }) {
    const params = {
      company_id: getters.company.id,
    }

    if (pdfResourceId != null) params.optional_pdf_resource_id = pdfResourceId

    const res = await http.get(`filing_methods/${filingMethodId}/schema`, {
      params: params,
    })

    let schema = res.data.result.schema

    if (Array.isArray(schema)) {
      schema =  schema.reduce((acc, step) => acc.concat(step.fields), [])
    }

    commit(t.SET_SCHEMA, schema)
  },

  setUseWrapperValidations({ commit }, use) {
    commit(t.SET_USE_WRAPPER_VALIDATIONS, use)
  },
  resetWrapperValidationObservers({ commit, dispatch }) {
    commit(t.RESET_WRAPPER_VALIDATION_OBSERVERS)
    dispatch('setShowWrapperValidationErrors', false)
    dispatch('setUseWrapperValidations', true)
  },
  updateWrapperValidationObservers({ commit }, { observerId, valid }) {
    commit(t.UPDATE_WRAPPER_VALIDATION_OBSERVERS, { observerId, valid })
  },
  setShowWrapperValidationErrors({ commit }, show) {
    commit(t.SET_SHOW_WRAPPER_VALIDATION_ERRORS, show)
  },
  setShowAddressPrivacyBorder({ commit }, show) {
    commit(t.SET_SHOW_ADDRESS_PRIVACY_BORDER, show)
  },
  resetState({ commit }) {
    commit(t.RESET_STATE)
  },
}

const MUTATIONS = {
  [t.SET_SHOW_ADDRESS_PRIVACY_BORDER](state, show) {
    Vue.set(state, 'showAddressPrivacyBorder', show)
  },
  [t.SET_SCHEMA_OBJECT_TYPE](state, currentObjectType) {
    state.currentObjectType = currentObjectType
  },
  [t.SET_SCHEMA_OBJECT](state, currentObject) {
    state.currentObject = currentObject
  },
  [t.SET_SCHEMA](state, schema) {
    state.schema = schema
  },
  [t.SET_USE_WRAPPER_VALIDATIONS](state, use) {
    state.useWrapperValidations = use
  },
  [t.RESET_WRAPPER_VALIDATION_OBSERVERS](state) {
    state.wrapperValidationObservers = {}
  },
  [t.UPDATE_WRAPPER_VALIDATION_OBSERVERS](state, { observerId, valid }) {
    state.wrapperValidationObservers = {
      ...state.wrapperValidationObservers,
      [observerId]: valid,
    }
  },
  [t.SET_SHOW_WRAPPER_VALIDATION_ERRORS](state, show) {
    state.showWrapperValidationErrors = show
  },
  [t.RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
