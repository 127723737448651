import Vuex from 'vuex'
import { sync } from 'vuex-router-sync'
import router from '@/routes'

import account from './modules/account.store'
import actionCable from './modules/actionCable.store'
import accountAccessKey from './modules/accountAccessKeys.store'
import accountWhitelistedIp from './modules/accountWhitelistedIps.store'
import alerts from './modules/alerts.store'
import app from './modules/app.store'
import cancellationReasons from './modules/cancellationReasons.store'
import checkout from './modules/checkout.store'
import companies from './modules/companies.store'
import companyDetails from './modules/companyDetails.store'
import creditCardProcessing from './modules/creditCardProcessing.store'
import dashpanel from './modules/dashpanel.store'
import dataMap from '@core/data-map-frontend/src/store/modules/dataMap.store'
import digitalWalletSurveys from './modules/digitalWalletSurveys.store'
import discounts from './modules/discounts.store'
import documents from './modules/documents.store'
import domains from './modules/domains.store'
import domainSuggestions from './modules/domainSuggestions.store'
import domainEmails from './modules/domainEmails.store'
import entityTypes from './modules/entityTypes.store'
import ephemeralMessage from './modules/ephemeralMessage.store'
import feed from './modules/feed.store'
import fileDrop from './modules/fileDrop.store'
import filingMethods from './modules/filingMethods.store'
import freeTrials from './modules/freeTrials.store'
import invitations from './modules/invitations.store'
import invoices from './modules/invoices.store'
import jurisdictions from './modules/jurisdictions.store'
import lawOnCall from './modules/lawOnCall.store'
import monitoring from './modules/monitoring.store'
import orderItems from './modules/orderItems.store'
import orders from './modules/orders.store'
import paymentMethods from './modules/paymentMethods.store'
import products from './modules/products.store'
import requestDocument from './modules/requestDocument.store'
import schema from './modules/schema.store'
import services from './modules/services.store'
import session from './modules/session.store'
import signatures from './modules/signatures.store'
import stagelineCompanyOverview from './modules/stagelineCompanyOverview.store'
import stagelineCreateCompany from './modules/stagelineCreateCompany.store'
import stagelineClientFeedback from './modules/stagelineClientFeedback.store'
import stagelineSchemaForm from './modules/stagelineSchemaForm.store'
import stagelineV3 from './modules/stagelineV3.store'
import subscription from './modules/subscription.store'
import vehicleRegistration from './modules/vehicleRegistration.store'
import verifyOrder from './modules/verifyOrder.store'
import virtualPhones from './modules/virtualPhones.store'
import vouchers from './modules/vouchers.store'
import website from './modules/website.store'
import faq from './modules/faq.store'

const MODULES = {
  account,
  actionCable,
  accountAccessKey,
  accountWhitelistedIp,
  alerts,
  app,
  cancellationReasons,
  checkout,
  companies,
  companyDetails,
  creditCardProcessing,
  dashpanel,
  dataMap,
  digitalWalletSurveys,
  discounts,
  documents,
  domains,
  domainSuggestions,
  domainEmails,
  entityTypes,
  ephemeralMessage,
  feed,
  fileDrop,
  filingMethods,
  freeTrials,
  invitations,
  invoices,
  jurisdictions,
  lawOnCall,
  monitoring,
  orderItems,
  orders,
  paymentMethods,
  products,
  schema,
  requestDocument,
  session,
  services,
  signatures,
  stagelineCompanyOverview,
  stagelineCreateCompany,
  stagelineClientFeedback,
  stagelineSchemaForm,
  stagelineV3,
  subscription,
  vehicleRegistration,
  verifyOrder,
  virtualPhones,
  vouchers,
  website,
  faq,
}

import Vue from 'vue'
import { GET_BUSY, GET_FROM } from '@/store/getters'
import { SET_BUSY, SET_FROM } from '@/store/mutations'
import { RESET, RESET_FROM } from '@/store/actions'
import { moduleSchema } from '@/store/moduleSchema'

export const state = () => {
  return {
    busy: false,
    from: null,
    moduleSchema: moduleSchema,
    draftId: null,
  }
}

const GETTERS = {
  [GET_FROM]: state => {
    return state.from
  },
  [GET_BUSY]: state => {
    return state.busy
  },
}

const ACTIONS = {
  [RESET]: ({ dispatch, commit }) => {
    const newState = state().moduleSchema
    commit('setModuleSchema', { moduleSchema: newState })
    commit(SET_BUSY, { busy: true })
    Object.keys(MODULES).forEach(function(mod) {
      dispatch(`${mod}/reset`, mod)
    })
    commit(SET_BUSY, { busy: false })
  },
  [RESET_FROM]: ({ commit }) => {
    commit(SET_FROM, { from: null })
  },
}

const MUTATIONS = {
  [SET_FROM]: (state, { from }) => {
    state.from = from
  },
  [SET_BUSY]: (state, { busy }) => {
    state.busy = busy
    window.document.body.style.cursor = busy ? 'progress' : 'auto'
  },
  setModuleSchema: (state, { moduleSchema }) => {
    state.moduleSchema = moduleSchema
  },
}

Vue.use(Vuex)

const store = new Vuex.Store({
  state: state(),
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
  modules: MODULES,
})

sync(store, router, { moduleName: 'appRouter' })

export default store
