import * as t from '../mutations'
import http from '@/http'
import { sortBy } from 'lodash/collection'
import {
  state as BaseState,
  getters as BaseGetters,
  actions as BaseActions,
  mutations as BaseMutations,
} from '@/store/base'

const STATE = {
  ...BaseState,
  namespace: 'jurisdictions',
  jurisdictions: [],
  jurisdictionsLoaded: false,
  unsupportedJurisdictions: [
    'American Samoa',
    'Federal',
    'Guam',
    'Northern Mariana Islands',
    'U.S. Virgin Islands',
  ],
  unitedStatesMapped: {
     1: { name: 'Alaska',               abbreviation: 'AK' },
    11: { name: 'Maine',                abbreviation: 'ME' },
    17: { name: 'Wisconsin',            abbreviation: 'WI' },
    21: { name: 'Vermont',              abbreviation: 'VT' },
    22: { name: 'New Hampshire',        abbreviation: 'NH' },
    23: { name: 'Washington',           abbreviation: 'WA' },
    24: { name: 'Idaho',                abbreviation: 'ID' },
    25: { name: 'Montana',              abbreviation: 'MT' },
    26: { name: 'North Dakota',         abbreviation: 'ND' },
    27: { name: 'Minnesota',            abbreviation: 'MN' },
    28: { name: 'Illinois',             abbreviation: 'IL' },
    29: { name: 'Michigan',             abbreviation: 'MI' },
    31: { name: 'New York',             abbreviation: 'NY' },
    32: { name: 'Massachusetts',        abbreviation: 'MA' },
    34: { name: 'Oregon',               abbreviation: 'OR' },
    35: { name: 'Nevada',               abbreviation: 'NV' },
    36: { name: 'Wyoming',              abbreviation: 'WY' },
    37: { name: 'South Dakota',         abbreviation: 'SD' },
    38: { name: 'Iowa',                 abbreviation: 'IA' },
    39: { name: 'Indiana',              abbreviation: 'IN' },
    40: { name: 'Ohio',                 abbreviation: 'OH' },
    41: { name: 'Pennsylvania',         abbreviation: 'PA' },
    42: { name: 'New Jersey',           abbreviation: 'NJ' },
    43: { name: 'Connecticut',          abbreviation: 'CT' },
    44: { name: 'Rhode Island',         abbreviation: 'RI' },
    45: { name: 'California',           abbreviation: 'CA' },
    46: { name: 'Utah',                 abbreviation: 'UT' },
    47: { name: 'Colorado',             abbreviation: 'CO' },
    48: { name: 'Nebraska',             abbreviation: 'NE' },
    49: { name: 'Missouri',             abbreviation: 'MO' },
    50: { name: 'Kentucky',             abbreviation: 'KY' },
    51: { name: 'West Virginia',        abbreviation: 'WV' },
    52: { name: 'Virginia',             abbreviation: 'VA' },
    53: { name: 'Maryland',             abbreviation: 'MD' },
    54: { name: 'Delaware',             abbreviation: 'DE' },
    57: { name: 'Arizona',              abbreviation: 'AZ' },
    58: { name: 'New Mexico',           abbreviation: 'NM' },
    59: { name: 'Kansas',               abbreviation: 'KS' },
    60: { name: 'Arkansas',             abbreviation: 'AR' },
    61: { name: 'Tennessee',            abbreviation: 'TN' },
    62: { name: 'North Carolina',       abbreviation: 'NC' },
    63: { name: 'South Carolina',       abbreviation: 'SC' },
    64: { name: 'District of Columbia', abbreviation: 'DC' },
    70: { name: 'Oklahoma',             abbreviation: 'OK' },
    71: { name: 'Louisiana',            abbreviation: 'LA' },
    72: { name: 'Mississippi',          abbreviation: 'MS' },
    73: { name: 'Alabama',              abbreviation: 'AL' },
    74: { name: 'Georgia',              abbreviation: 'GA' },
    78: { name: 'Hawaii',               abbreviation: 'HI' },
    81: { name: 'Texas',                abbreviation: 'TX' },
    86: { name: 'Florida',              abbreviation: 'FL' },
    99: { name: 'Puerto Rico',          abbreviation: 'PR' },
  },
}

const GETTERS = {
  ...BaseGetters,
  jurisdictionsLoaded: state => state.jurisdictionsLoaded,
  jurisdictions: state => state.jurisdictions.filter(j => !state.unsupportedJurisdictions.includes(j.state_province_region)),
  allJurisdictions: state => state.jurisdictions,
  stateNames: state => state.jurisdictions.map(j => j.state_province_region),
  stateAbbreviations: state => sortBy(state.jurisdictions.map(j => j.abbreviation)),
  findByName: state => name => state.jurisdictions.find(j => name && name === j.state_province_region),
  findByAbbreviation: state => abbreviation => state.jurisdictions.find(j => j.abbreviation === abbreviation),
  findById: state => id => state.jurisdictions.find(j => j.id === id),
  formsLibraryJurisdictions: state => state.jurisdictions.filter(j => !state.unsupportedJurisdictions.filter(val => val !== 'Federal').includes(j.state_province_region)),
  convertToBaseJurisdiction: (_state, getters) => jur => {
    // if variable is an object, it's already the full jurisdiction so just return it
    if (typeof(jur) === 'object') {
      return jur
    }
    // use abbreviation if that is sent in
    if (jur.length === 2) {
      return getters.findByAbbreviation(jur)
    }

    // otherwise, use state_province_region or formatted
    return getters.findByName(jur)
  },
  unitedStatesMapped: state => state.unitedStatesMapped,
  findStateNameByMapId: state => id => state.unitedStatesMapped[id]?.name || '',
}

const ACTIONS = {
  ...BaseActions,
  async load({ commit, getters }) {
    if (getters.jurisdictionsLoaded) {
      return
    }

    commit(t.LOAD_JURISDICTIONS_START)

    const response = await http.get('jurisdictions')

    commit(t.LOAD_JURISDICTIONS_FINISHED, response.data.result)
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.LOAD_JURISDICTIONS_START](state) {
    state.jurisdictionsLoaded = false
  },

  [t.LOAD_JURISDICTIONS_FINISHED](state, jurisdictions) {
    state.jurisdictions = sortBy(jurisdictions, 'state_province_region')

    state.jurisdictionsLoaded = true
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}

