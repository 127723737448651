import axios from 'axios'

const jsonApiMediaType = 'application/vnd.api+json'

const normalizeUrl = (url) => {
  const decodedURI = decodeURI(url)
  try {
    return new URL(decodedURI)
  } catch {
    return {
      pathname: decodedURI,
    }
  }
}

const convertArraysToCommaString = (obj) => {
  if (typeof obj === 'string') return obj
  if (Array.isArray(obj)) return obj.join(',')
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, Array.isArray(value) ? value.join(',') : value])
  )
}

// Need to format queries correctly for Rails, ex: page[number]=1.
function serializeQuery(params, prefix = '') {
  const result = {}

  Object.keys(params).forEach((key) => {
    const value = params[key]
    let newKey = prefix ? `${prefix}[${key}]` : key

    if (value && typeof value === 'object') {
      Object.assign(result, serializeQuery(value, newKey))
    } else {
      result[newKey] = value
    }
  })

  return result
}

const jsonApiDeserializer = (response, depth = 0, processed = {}, parentKey = null) => {
  const maxDepth = 10
  if (depth >= maxDepth) {
    return { error: "max depth reached" }
  }

  const { data } = response

  if (Array.isArray(data)) {
    return data.map(item => jsonApiDeserializer({ data: item, included: response.included }, depth, processed))
  }

  const key = `${response.key || data.type}-${data.id}-${parentKey || data.type}`

  // Prevent circular logic
  if (processed.hasOwnProperty(key)) {
    return JSON.parse(JSON.stringify(processed[key]))
  }

  const result = { ...data.attributes, id: data.id, type: data.type }

  processed[key] = JSON.parse(JSON.stringify(result))

  if (Object.keys(data.relationships || {}).length) {
    Object.keys(data.relationships).forEach(key => {
      const { data: relData, links } = data.relationships[key]
      const isArray = Array.isArray(relData)
      if (isArray) {
        result[key] = []
      }
      const dataArray = isArray ? relData : [relData]
      dataArray.forEach((relItem) => {
        if (!relItem) return

        const includedItem = response.included?.find(
          (inc) => inc.id === relItem.id && inc.type === relItem.type
        )

        const item = includedItem ? jsonApiDeserializer(
          { data: includedItem, included: response.included, key },
          depth + 1,
          processed,
          response.key,
        ) : relItem
        item._loaded = !!includedItem

        if (links?.related) {
          item._link = links.related
        }

        isArray ? result[key].push(item) : result[key] = item
      })
    })
  }

  if (depth === 0) {
    result._json_api = response
  }

  return result
}

// TODO: Allow ability to add relationships.
const jsonApiSerializer = ({ id = null, type, data = {} }) => {
  return {
    data: {
      type,
      ...(!!id && { id }),
      attributes: data,
    },
  }
}

const jsonApiRequest = async (method, url, config, {
  data = null,
  type = null,
  serialize = false,
  deserialize = true,
  page = { number: null, size: null },
  sort = '',
  include = '',
  fields = {},
  filter = {},
  ...queryParams
}) => {
  const urlObj = normalizeUrl(url)
  const searchParams = urlObj.searchParams ? Object.fromEntries(urlObj.searchParams.entries()) : {}

  const requestData = serialize && type ? jsonApiSerializer({ type, data }) : data
  config['headers'] = {
    ...(config['headers'] || {}),
    'Content-Type': jsonApiMediaType,
    'Accept': jsonApiMediaType,
  }

  const axiosConfig = {
    method,
    url: `${urlObj.pathname}.jsonapi`,
    params: serializeQuery({
      ...(include && { include: convertArraysToCommaString(include) }),
      ...(fields && { fields: convertArraysToCommaString(fields) }),
      ...(sort && { sort: convertArraysToCommaString(sort) }),
      ...(page?.number != null && page?.size != null && { page }),
      ...(filter && { filter: convertArraysToCommaString(filter) }),
      ...queryParams,
      ...searchParams, // Search params from the URL take priority over query params
    }),
    ...config,
  }

  if (method !== 'get') {
    axiosConfig.data = requestData
  }

  const response = await axios(axiosConfig)
  return deserialize ? jsonApiDeserializer(response.data) : response.data
}

const jsonApiPost = (url, config, options) => {
  return jsonApiRequest('post', url, config, options)
}

const jsonApiPatch = (url, config, options) => {
  return jsonApiRequest('patch', url, config, options)
}

const jsonApiGet = (url, config, options) => {
  return jsonApiRequest('get', url, config, options)
}

export default {
  jsonApi: {
    deserialize: jsonApiDeserializer,
    serialize: jsonApiSerializer,
    get: jsonApiGet,
    post: jsonApiPost,
    patch: jsonApiPatch,
  },
}
