import Vue from 'vue'
import * as t from '../mutations'
import http from '@/http'
import { clone } from 'lodash'
import { CLIENT_DOMAINS } from '@/api/v3/endpoints'
import * as Cookies from 'js-cookie'
import store from '@/store'

export const RECORD_TYPE_MAPPING = {
  A: 'external_host',
  AAAA: 'external_host',
  MX: 'mx',
  TXT: 'txt',
  CNAME: 'subdomain',
  NS: 'ns',
  SRV: 'srv',
  CAA: 'caa',
  nameserver: 'nameserver',
  glue_record: 'glue_record',
}

export const IPV6_REGEX = `(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|` +
  `([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|` +
  `([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|` +
  `([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|` +
  `[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|` +
  `fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|` +
  `1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|` +
  `([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|` +
  `(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))`
export const IPV4_REGEX = `^(?:[0-9]{1,3}.){3}[0-9]{1,3}$`

const getDefaultState = () => {
  return {
    domain: null,
    domains: null,
    nameservers: null,
    internetNameservers: null,
    domainSsl: null,
    webHosting: {},
    tlds: null,
    hostingTransferInfo: null,
    standaloneHostingInfo: null,
    domainTransferRequests: [],
    domainWhois: {},
    selectedDomainRecord: null,
    requestInProgress: false,
    selectedDomain: null,
    domainSearch: null,
    domainSearchAvailable: false,
    domainSearchResults: null,
    domainsForAccount: null,
    domainAvailability: {},
  }
}
const STATE = getDefaultState()

const GETTERS = {
  altDateCheck: (_, getters) => !getters.isPterodactylHostingAccount && getters.domain?.created_at < '2024-12-18 11:50:54',
  domain: state => state.domain,
  domains: state => state.domains,
  domainsForAccount: state => state.domainsForAccount,
  domainAvailability: state => state.domainAvailability,
  domainSsl: state => state.domainSsl,
  domainHostingInfo: (_state, getters) => getters.domain?.domain_hosting_info || null,
  isCpanelHostingAccount: (_state, getters) => getters.domainHostingInfo?.platform === 'cpanel',
  isPterodactylHostingAccount: (_state, getters) => getters.domainHostingInfo?.platform === 'pterodactyl',
  webHosting: state => state.webHosting,
  tlds: state => state.tlds,
  hostingTransferInfo: state => state.hostingTransferInfo,
  standaloneHostingInfo: state => state.standaloneHostingInfo,
  activeDomainTransferRequest: state => state.domainTransferRequests.find(hti =>
    !hti.status.toLowerCase().includes('cancel') && !hti.status.toLowerCase().includes('expired')),
  domainTransferRequests: state => state.domainTransferRequests,
  domainSearch: state => state.domainSearch,
  domainSearchResults: state => state.domainSearchResults,
  domainSearchAvailable: (_, getters) => !!getters.domainSearchResults?.some(value =>
    value?.available && value?.domain === getters.domainSearch
  ),
  selectedDomain: state => state.selectedDomain,
  domainRecords: (_, getters) => getters.domain.records.map(record => {
    return { ...record, record_type: RECORD_TYPE_MAPPING[record.type] }
  }),
  domainWhois: state => state.domainWhois,
  externalHosts: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'external_host'),
  txtRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'txt'),
  subdomains: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'subdomain'),
  nsRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'ns'),
  srvRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'srv'),
  caaRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'caa'),
  mxRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'mx'),
  recordHostname: (_, getters) => (record) => `${record.name || ''}${getters.domain.domain_name}`,
  nameservers: state => state.nameservers,
  internetNameservers: state => state.internetNameservers,
  selectedDomainRecord: (state) => state.selectedDomainRecord,
  showAdminTools:        () => {
    const adminCookie = Cookies.get('admin') === 'logged_in_as_client'
    const sessionFlag = sessionStorage.getItem('admin-logged-in')
    const domainAdminFlag = sessionStorage.getItem('domain-admin')

    return adminCookie && sessionFlag && domainAdminFlag
  },
}

const ACTIONS = {
  // Domains ---------------------------------------------------------------------------------------
  async swapDomain({ dispatch }, { id, sourceDomain, targetDomain, doCallback = false }) {
    try {
      const params = {
        source_domain: sourceDomain,
        target_domain: targetDomain,
      }

      const domainResult = await http.put(`client/domains/${id}/swap`, params)

      if(!doCallback) {
        if (domainResult.data.success) {
          const domainSwapped = domainResult.data.response
          dispatch('dashpanel/swapDomain', domainSwapped, { root: true })
          return domainResult.data
        }
      } else {
        return new Promise((resolve) => {
          const callbackFunction = function(result) {
            resolve(result)
          }

          store.dispatch("actionCable/addRequestCallbackFunction", {
            requestId: domainResult.data.request_id,
            callbackFunction,
            requestName: "Domain Swap",
          })
        })
      }
    } catch(error) {
      return error
    }
  },
  async renewDomain({ _ }, { domain, autopay_id }) {
    try {
      const params = {
        payable_id: autopay_id,
      }

      return await http.post(`client/domains/${domain.id}/renewal`, params)
    } catch(error) {
      return error
    }
  },
  async getRenewalPrice({ _ }, { domain }) {
    try {
      const domainResult = await http.get(`client/domains/${domain.id}/renewal`)

      if (domainResult.data.request_id) {
        return new Promise((resolve, reject) => {
          const callbackFunction = function (result) {
            if (result.success) {
              resolve(result.renew)
            } else {
              reject("Failed to get renewal price")
            }
          }
          store.dispatch("actionCable/addRequestCallbackFunction", {
            requestId: domainResult.data.request_id,
            callbackFunction,
            requestName: "Get Renewal Price",
          })
        })
      }
    } catch(error) {
      return error
    }
  },
  async createDomain({ commit, getters, dispatch }, { companyId, synchronous = false }) {
    try {
      const params = {
        domain_name: getters.selectedDomain,
        company_id: companyId,
        use_info_on_file: 'true',
        privacy: 'true',
      }
      const response = await http.post(`client/domains`, params)

      const callbackFunction = function(result) {
        const domainCreated = result
        commit(t.SET_DOMAIN, domainCreated)
        dispatch('dashpanel/addDomain', domainCreated, { root: true })
      }

      if (synchronous) {
        return new Promise((resolve) => {
          const callbackFunctionSync = function(result) {
            callbackFunction(result)
            resolve(result)
          }

          store.dispatch("actionCable/addRequestCallbackFunction", {
            requestId: response.data.request_id,
            callbackFunction: callbackFunctionSync,
            requestName: "Domain Create",
          })
        })
      } else {
        await store.dispatch("actionCable/addRequestCallbackFunction", {
            requestId: response.data.request_id,
            callbackFunction,
            requestName: "Domain Create",
          }
        )
      }
    } catch(error) {
      return error
    }
  },
  async setWhois({ commit, getters }, { id, newWhois, type }) {
    const response = await http.put(`client/domains/${id}/whois`, { whois: newWhois, type: type })

    const callbackFunction = function(result) {
      const domainWhois = { ...getters.domainWhois }
      const whois = result
      const updateDomainWhois = { ...domainWhois, ...whois }
      commit(t.SET_DOMAIN_WHOIS, updateDomainWhois)
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Set Domain Contact" })
  },
  async fetchDomainAuthCode({ commit }, { domain }) {
    const response = await http.get(`client/domains/${domain.id}/auth_code`)

    const callbackFunction = function(result) {
      commit(t.SET_DOMAIN_AUTH_CODE, result.auth_code)
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Fetch Domain Auth Code" })
  },
  async addService({ _ }, { domainId, doCallback = false, callbackFunction = null, synchronous = false }) {
    try {
      const response = await http.post(`client/domains/${domainId}/addons`)
      if (doCallback) {
        if (synchronous) {
          return new Promise((resolve) => {
            const callbackFunctionSync = function(result) {
              if (callbackFunction) {
                resolve(callbackFunction(result))
              } else {
                resolve(result)
              }
            }

            store.dispatch("actionCable/addRequestCallbackFunction", {
              requestId: response.data.request_id,
              callbackFunction: callbackFunctionSync,
              requestName: "Addon Setup",
              showResponseToast: true,
              showRequestToast: true,
            })
          })
        }
        await store.dispatch("actionCable/addRequestCallbackFunction", {
          requestId: response.data.request_id,
          callbackFunction,
          requestName: "Addon Setup",
          showResponseToast: true,
          showRequestToast: true,
        })
      } else {
        return response
      }
    } catch(error) {
      return { success: false, error: error }
    }
  },
  async createHosting({ commit }, { domainId, synchronous = false }) {
    try {
      const response = await http.post(`client/domains/${domainId}/hosting`)

      const callbackFunction = function(result) {
        if (result.data.success) {
          commit(t.SET_WEB_HOSTING, result.data.response)
          return { success: true }
        }
      }

      if (synchronous) {
        return new Promise((resolve) => {
          const callbackFunctionSync = function(result) {
            resolve(callbackFunction(result))
          }

          store.dispatch("actionCable/addRequestCallbackFunction", {
            requestId: response.data.request_id,
            callbackFunction: callbackFunctionSync,
            requestName: "Domain Create",
            showResponseToast: true,
            showRequestToast: true,
          })
        })
      }

      await store.dispatch("actionCable/addRequestCallbackFunction", {
          requestId: response.data.request_id,
          callbackFunction,
          requestName: "Hosting Create",
          showResponseToast: true,
          showRequestToast: true,
        })
    } catch(error) {
      return { success: false, error: error }
    }
  },
  async reinstallWordpress({ _ }, id ) {
    return await http.post(`client/domains/${id}/hosting/reinstall`)
  },
  async changeWordpressPassword({ _ }, { domainId, password, afterFunction }) {
    const response = await http.patch(`client/domains/${domainId}/hosting/password_change`, {
      password: password,
      sensitive: ["password"],
    })
    const callbackFunction = function(result) {
      afterFunction(result)
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Update Wordpress Password" })
  },
  async createSSL({ getters }, { data }) {
    try {
      const domainId = getters.domain.id
      const result = await http.post(`client/domains/${domainId}/domain_ssl`, {
        domain_id: domainId,
        data: {
            default: data,
        },
      })
      return { success: result.data.success }
    } catch(error) {
      return { success: false, error: error }
    }
  },
  async revalidateSsl({ getters }, cert_order_id) {
    try {
      const domainId = getters.domain.id
      const result = await http.put(`client/domains/${domainId}/domain_ssl`, {
        cert_order_id: cert_order_id,
      })

      return { success: result.data.success }
    } catch (error) {
      return { success: false, error: error }
    }
  },
  async fetchDomainSslDownloadUrl({ getters }, cert_order_id) {
    try {
      const domainId = getters.domain.id
      const result = await http.get(`${CLIENT_DOMAINS}/${domainId}/domain_ssl`, {
        cert_order_id: cert_order_id,
      })

      return result.data.response.s3_url
    } catch (error) {
      return { success: false, error: error }
    }
  },
  async fetchMagicLink({ _ }, companyLink) {
    const response = await http.get(companyLink)

    if (response.data.success) {
      return response.data.response.url
    }
  },
  async fetchHostingStatus({ _getters }, id ) {
    const statusRes = await http.get(`client/domains/${id}/hosting`)

    return statusRes.data.response
  },
  async fetchDomains({ getters, commit }) {
    const domainRes = await http.get(`client/domains`)
    commit(t.SET_DOMAINS, domainRes.data.result)
    return getters.domains
  },
  async fetchDomain({ getters, commit }, { id }) {
    const domains = await ACTIONS.fetchDomains({ getters, commit })
    const domain = domains.find(d => d.id === id)
    commit(t.SET_DOMAIN, domain)
  },
  async fetchDomainInfo({ commit, getters }, { domain }) {
    try {
      const response = await http.get(`client/domains/${domain.id}`)

      if (getters.isCpanelHostingAccount || getters.altDateCheck) {
        domain.domain_privacy = response.data.response.domain_privacy
        commit(t.SET_DOMAIN, domain)
        commit(t.SET_DOMAIN_NAMESERVERS, response.data.response.domain_name_servers)
        commit(t.SET_NAMESERVERS, response.data.response.domain_name_servers)
      } else {
        const callbackFunction = function(result) {
          const nameservers = result.domain_name_servers
          domain.domain_privacy = result.domain_privacy

          commit(t.SET_DOMAIN, domain)
          commit(t.SET_DOMAIN_NAMESERVERS, nameservers)
          commit(t.SET_NAMESERVERS, nameservers)
        }

        await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Domain Info" })
      }
    } catch (error) {
      return error
    }
  },
  async fetchDnsRecords({ commit, getters }, { domain }) {
    try {
      const response = await http.get(`client/domains/${domain.id}/dns`)
      if (getters.isCpanelHostingAccount || getters.altDateCheck) {
        commit(t.SET_DOMAIN_RECORDS, response.data.response?.hosts || undefined)
      } else {
        const callbackFunction = function(result) {
          commit(t.SET_DOMAIN_RECORDS, result.hosts || undefined)
        }
        await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Fetch Domain Dns Records" })
      }
    } catch (error) {
      return error
    }
  },
  async fetchMostRecentDomainAndEmail({ commit, dispatch }, companyId) {
    const domains = await ACTIONS.fetchDomains()

    if (domains?.length) {
      const companyDomain = domains.find(d => d.company_id === companyId)
      if (companyDomain) {
        commit(t.SET_DOMAIN, companyDomain)
        dispatch('domainEmails/fetchDomainEmails', {
          domainId: companyDomain.id,
          setMostRecentDomain: true,
        }, { root: true })
      } else {
        commit(t.RESET_DOMAINS)
        dispatch('domainEmails/resetDomainEmail', null, { root: true })
      }
    }
  },
  async searchDomains({ commit, dispatch }, domainSearch) {
    if (domainSearch === null) {
      commit(t.SET_DOMAIN_SEARCH, domainSearch)
      return
    }

    const domains = [domainSearch]

    const params = {
      domains: domains,
    }

    try {
      const response = await http.post(`client/domains/search`, params)

      const callbackFunction = function(result) {
        const tld = domainSearch.slice(domainSearch.indexOf('.'))
        const domains = result.domains.filter(domainData => domainData.available)
          .sort((a, b) => {
            // If domain matches search, push to the top of results.
            if (a.domain === domainSearch) {
              return -1
            }
            if (b.domain === domainSearch) {
              return 1
            }

            const aTld = a.domain.slice(a.domain.indexOf('.'))
            const bTld = b.domain.slice(b.domain.indexOf('.'))

            // If tld matches search tld, push to the top of results.
            if (aTld === tld) {
              return -1
            }
            if (bTld === tld) {
              return 1
            }

            // Otherwise, order by tld alphabetically, then domain alphabetically
            if (aTld < bTld) {
              return -1
            }
            if (aTld > bTld) {
              return 1
            }
            if (a.domain < b.domain) {
              return -1
            }
            if (a.domain > b.domain) {
              return 1
            }

            return 0
          })
        commit(t.SET_DOMAIN_SEARCH, domainSearch)
        dispatch('setDomainSearchResults', domains !== undefined ? domains : [])
      }
      await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Domain Bulk Check" })
    } catch(error) {
      return error
    }
  },
  async fetchDomainAvailability({ commit }, domainName) {
    try {
      const params = {
        domains: [domainName],
      }
      const response = await http.post(`${CLIENT_DOMAINS}/availability/check_availability`, params)

      return new Promise((resolve) => {
        const callbackFunction = function(result) {
          commit(t.SET_DOMAIN_AVAILABILITY, result.domains)
          resolve(result.domains)
        }

        store.dispatch("actionCable/addRequestCallbackFunction", {
          requestId: response.data.request_id,
          callbackFunction,
          requestName: "Domain Availability Check",
        })
      })
    } catch(error) {
      return Promise.reject(error)
    }
  },
  async setDomain({ commit, _dispatch }, domain) {
    commit(t.SET_DOMAIN, domain)
  },
  async fetchDomainWhois({ commit, getters }, { domain, showPrivate }) {
    try {
      const params = {}
      params.private = showPrivate ? showPrivate : undefined
      const response = await http.get(`${CLIENT_DOMAINS}/${domain.id}/whois`, { params: params })

      if (getters.isCpanelHostingAccount || getters.altDateCheck) {
        const domainWhois = response.data.response
        commit(t.SET_DOMAIN_WHOIS, domainWhois)
      } else {
        const callbackFunction = function(result) {
          commit(t.SET_DOMAIN_WHOIS, result)
        }

        await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Fetch Domain Contact" })
      }
    } catch(error) {
      commit(t.SET_DOMAIN_WHOIS, {})
    }
  },
  async fetchDomainSsl({ commit }, { id }) {
    const domainSslRes = await http.get(`${CLIENT_DOMAINS}/${id}/domain_ssl`)
    const domainSsl = domainSslRes.data.response

    commit(t.SET_DOMAIN_SSL, domainSsl)

    return domainSsl.length === 0 ? null : domainSsl[0]
  },
  async setSelectedDomain({ commit, _dispatch }, domain) {
    commit(t.SET_SELECTED_DOMAIN, domain)
  },
  async setDomainSearchResults({ commit, _dispatch }, domainSearchResults) {
    commit(t.SET_DOMAIN_SEARCH_RESULTS, domainSearchResults)
  },
  async lockDomain({ _, getters }, { id }) {
    const response = await http.put(`client/domains/${id}/lock?lock_action=lock`)

    const callbackFunction = function(result) {
      const domain = getters.domains.find((domain) => domain.domain_name === result.domain)
      domain.locked = result.locked
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Domain Lock", showResponseToast: true })
  },
  async unlockDomain({ _, getters }, { id }) {
    const response = await http.put(`client/domains/${id}/lock?lock_action=unlock`)

    const callbackFunction = function(result) {
      const domain = getters.domains.find((domain) => domain.domain_name === result.domain)
      domain.locked = result.locked
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Domain Unlock", showResponseToast: true })
  },

  setSelectedDomainRecord({ commit }, { record }) {
    commit(t.SET_SELECTED_DOMAIN_RECORD, record)
  },

  async fetchDomainsForAccount({ commit }) {
    const response = await http.get(`client/domains`)
    commit(t.SET_DOMAINS_FOR_ACCOUNT, response.data.result)
  },

  // DNS Record CRUD -------------------------------------------------------------------------------
  async legacyCreateDnsRecord({ commit }, { domainId, record }) {
    try {
      const res = await http.post(`client/domains/${domainId}/dns`, {
        record_type: RECORD_TYPE_MAPPING[record.type],
        type: record.type,
        priority: record.priority || 0,
        name: record.name,
        data: record.data,
        ttl: record.ttl,
      })

      if (res.data?.status === 200) {
        const createdRecord = res.data.response.hosts[0]
        commit(t.ADD_DOMAIN_RECORD, createdRecord)
      }

      return res
    } catch(error) {
      let errorMessage = "Creation failed."
      if (error.response?.data?.errors) {
        errorMessage = `${Object.keys(error.response?.data?.errors)[0]}: ${Object.values(error.response?.data?.errors)[0]}`
      }
      return { success: false, error: errorMessage }
    }
  },
  async createDnsRecord({ commit, dispatch, getters }, { domain, record }) {

    if (getters.isCpanelHostingAccount || getters.altDateCheck) {
      return await dispatch('legacyCreateDnsRecord', { domainId: domain.id, record })
    }

    try {
      const updateRecords = JSON.parse(JSON.stringify(domain.records))
      updateRecords.push(record)

      updateRecords.forEach((rec) => rec.changetype = "REPLACE")

      const response = await http.put(`client/domains/${domain.id}/dns`, {
        records: updateRecords,
      })

      const callbackFunction = function(_result) {
        record.temp = false

        const records = JSON.parse(JSON.stringify(domain.records))
        records.push(record)

        commit(t.SET_DOMAIN_RECORDS, records)
      }

      await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Create DNS Record", showResponseToast: true })
    } catch(error) {
      let errorMessage = "Creation failed."
      if (error.response?.data?.errors) {
        errorMessage = `${Object.keys(error.response?.data?.errors)[0]}: ${Object.values(error.response?.data?.errors)[0]}`
      }
      return { success: false, error: errorMessage }
    }
  },
  async legacyUpdateDnsRecord({ commit }, { domainId, record }) {
    try {
      const res = await http.put(`client/domains/${domainId}/dns`, {
        record_type: 'external_host',
        type: record.type,
        records: record.id, //The new validator needs this, and we can pass through the actual record ID
        priority: record.priority || 0,
        name: record.name,
        data: record.data,
        ttl: record.ttl,
        id: record.id,
      })

      if (res.data?.status === 200) {
        const updatedRecord = res.data.response.hosts[0]
        commit(t.UPDATE_DOMAIN_RECORD, updatedRecord)
      }

      return res
    } catch(error) {
      let errorMessage = "Update failed."
      if (error.response?.data?.errors) {
        errorMessage = `${Object.keys(error.response?.data?.errors)[0]}: ${Object.values(error.response?.data?.errors)[0]}`
      }
      return { success: false, error: errorMessage }
    }
  },
  async updateDnsRecord({ commit, dispatch, getters }, { domain, index, record }) {

    if (getters.isCpanelHostingAccount || getters.altDateCheck) {
      return await dispatch('legacyUpdateDnsRecord', { domainId: domain.id, record })
    }

    else {
      try {
        const updateRecords = JSON.parse(JSON.stringify(domain.records))
        const oldRec = domain.records[index]

        const oldRecUpdate = updateRecords[index]
        updateRecords[index] = record

        updateRecords.forEach((rec) => rec.changetype = "REPLACE")
        oldRecUpdate.changetype = "DELETE"

        updateRecords.push(oldRecUpdate)

        const response = await http.put(`client/domains/${domain.id}/dns`, {
          records: updateRecords,
        })

        const callbackFunction = function(_result) {
          const records = JSON.parse(JSON.stringify(domain.records))

          records.splice(domain.records.indexOf(oldRec), 1)
          records.push(record)

          commit(t.SET_DOMAIN_RECORDS, records)
        }

        await store.dispatch("actionCable/addRequestCallbackFunction", {
          requestId: response.data.request_id,
          callbackFunction,
          requestName: "Update DNS Record",
          showResponseToast: true,
        })
      } catch (error) {
        let errorMessage = "Update failed."
        if (error.response?.data?.errors) {
          errorMessage = `${Object.keys(error.response?.data?.errors)[0]}: ${Object.values(error.response?.data?.errors)[0]}`
        }
        return { success: false, error: errorMessage }
      }
    }
  },
  async legacyDeleteDnsRecord({ commit }, { domain, record }) {
    const res = await http.delete(`client/domains/${domain.id}/dns`, {
      data: {
        records: record.id,
      },
    })

    if (res.data?.status === 200) {
      const records = JSON.parse(JSON.stringify(domain.records))
      records.splice(domain.records.indexOf(record), 1)

      commit(t.SET_DOMAIN_RECORDS, records)
    }

    return res
  },
  async deleteDnsRecord({ commit, dispatch, getters }, { domain, index }) {
    const deleteRecords = domain.records.map((record, idx) => ({
      ...record,
      changetype: idx === index ? 'DELETE' : 'REPLACE',
    }))

    const record = domain.records[index]

    if (getters.isCpanelHostingAccount || getters.altDateCheck) {
      return await dispatch('legacyDeleteDnsRecord', { domain, record })
    }

     else {
      const response = await http.put(`client/domains/${domain.id}/dns`, {
        records: deleteRecords,
      })

      const callbackFunction = function(_result) {
        const records = JSON.parse(JSON.stringify(domain.records))
        records.splice(domain.records.indexOf(record), 1)

        commit(t.SET_DOMAIN_RECORDS, records)
      }

      await store.dispatch("actionCable/addRequestCallbackFunction", {
        requestId: response.data.request_id,
        callbackFunction,
        requestName: "Delete DNS Record",
        showResponseToast: true,
      })
    }
  },
  async addDomainNameserver({ getters, dispatch }, { domainId, nameserver }) {
    const nameservers = [...new Set([...getters.nameservers, nameserver])]
    return dispatch('setDomainNameservers', { domainId, nameservers, operation: "Add" })
  },
  async updateDomainNameserver({ getters, dispatch }, { domainId, index, nameserver }) {
    let nameservers = clone(getters.nameservers)
    nameservers.splice(index, 1, nameserver)
    nameservers = [...new Set([...nameservers])]
    return dispatch('setDomainNameservers', { domainId, nameservers, operation: "Update" })
  },
  async fetchDomainNameservers({ commit }, { domain }) {
    const res = await http.get(`client/domains/${domain.id}/nameservers?domain_name=${domain.domain_name}`)

    if (res.data?.status === 200) {
      commit(t.SET_DOMAIN_INTERNET_NAMESERVERS, res.data.response)
    }
  },
  async fetchTlds({ commit }) {
    const res = await http.get(`client/domains/tlds`)

    if (res.data?.status === 200) {
      commit(t.SET_TLDS, res.data.response)
    }
  },
  async deleteDomainNameserver({ getters, dispatch }, { domainId, nameserver }) {
    let nameservers = clone(getters.nameservers)
    nameservers = nameservers.filter(ns => ns !== nameserver)
    return dispatch('setDomainNameservers', { domainId, nameservers, operation: "Delete" })
  },
  async setDomainNameservers({ commit, getters }, { domainId, nameservers, operation = "Set" }) {
    const response = await http.patch(`client/domains/${domainId}/nameservers`, {
        nameservers: nameservers,
      })

    if (getters.isCpanelHostingAccount || getters.altDateCheck) {
      if (response.data?.status === 200) {
        commit(t.SET_DOMAIN_NAMESERVERS, nameservers)
        commit(t.SET_NAMESERVERS, nameservers)
      }

      return response
    }
    else {
      const callbackFunction = (result) => {
        commit(t.SET_DOMAIN_NAMESERVERS, result.nameservers)
        commit(t.SET_NAMESERVERS, result.nameservers)
      }

      await store.dispatch("actionCable/addRequestCallbackFunction", {
        requestId: response.data.request_id,
        callbackFunction,
        requestName: `${operation} Domain Nameserver`,
        showResponseToast: true,
      })
    }
  },
  async upsertDomainGlueRecords({ getters, dispatch }, { record }) {
    const ips = [...record.ipv4, ...record.ipv6]
    const requests = []
    for (const ip of ips) {
      const rec = {
        type: 'NS',
        name: ip,
        data: record.data,
        ttl: 900,
      }

      const match = getters.nsRecords.find(r => {
        return r.name === ip && r.data === rec.data
      })

      // If there's a match, we already have the record we need, no need to create
      if (!match) {
        requests.push(dispatch('createDnsRecord', { domain: this.domain, record: rec }))
      }
    }

    return await Promise.all(requests)
  },
  // Domain transfer request------------------------------------------------------------------------
  async fetchDomainTransferRequests({ commit }, companyId) {
    const apiResponse = await http.get(`client/domains/domain_transfers/${companyId}`)
    if(apiResponse.data.success && apiResponse.data.response) {
      commit(t.SET_DOMAIN_TRANSFER_REQUESTS, apiResponse.data.response)
    }

    return apiResponse
  },
  async fetchDomainTransferRequest({ commit }, id) {
    const apiResponse = await http.get(`client/domains/domain_transfers/${id}`)
    if(apiResponse.data.success && apiResponse.data.response){
      commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, apiResponse.data.response)
    }

    return apiResponse
  },
  async submitDomainTransferRequest({ commit }, { company_id, domain_name, authorization_code, payable_id, afterFunction }) {
    const response = await http.post('client/domains/domain_transfers',
      {
        company_id: company_id,
        domain_name: domain_name,
        authorization_code: authorization_code,
        payable_id: payable_id,
      })

    const callbackFunction = function(result) {
      afterFunction(result)
      if (result?.success) {
        commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, result)
      }
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Create Transfer Request" })
  },
  async cancelDomainTransferRequest({ commit }, id) {
    try {
      const response = await http.put(`client/domains/domain_transfers/${id}`, { status: "cancelled" })

      if (response.data?.success) {
        commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, response.data.response)
      }
    } catch (error) {
      return error
    }
  },
  async acceptDomainTransferRequestFoa({ commit }, { id, foa_token, afterFunction }){
    const response = await http.put(`client/domains/domain_transfers/${id}`, { status: "pending", foa_token })

    const callbackFunction = function(result) {
      afterFunction(result)
      if (result?.success) {
        commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, result)
      }
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Accept Transfer Request" })
  },

  // Hosting transfer request-----------------------------------------------------------------------
  async fetchHostingTransferRequest({ commit }, { companyId, showCredentials = false }) {
    const apiResponse = await http.get(`client/domains/hosting/hosting_transfers/${companyId}`,
      { params: { show_credentials: showCredentials } })
    if(apiResponse.data.success && apiResponse.data.response){
      const { response } = apiResponse.data
      commit(t.SET_HOSTING_TRANSFER_INFO, {
        id: response.id,
        provider: response.provider,
        companyId: response.company_id,
        username: response.username,
        password: response.password,
        requestCall: response.request_call,
        status: response.status,
        admin: response.admin,
      })
    }

    return apiResponse
  },
  async submitHostingTransferRequest({ commit }, { provider, username, password, companyId, requestCall }) {
    const apiResponse = await http.post(`client/domains/hosting/hosting_transfers`,
      {
      provider,
      username,
      password,
      company_id: companyId,
      request_call: requestCall,
    })
    if(apiResponse.data.success && apiResponse.data.response){
      const { response } = apiResponse.data
      commit(t.SET_HOSTING_TRANSFER_INFO, {
        id: response.id,
        provider: response.provider,
        companyId: response.company_id,
        username: response.username,
        password: response.password,
        requestCall: response.request_call,
        status: response.status,
        admin: response.admin,
      })
    }

    return apiResponse
  },
  async updateHostingTransferRequest({ commit, getters }, { provider, username, password, companyId, requestCall, id }) {
    const apiResponse = await http.put(`client/domains/hosting/hosting_transfers/${companyId}`,
      {
      provider,
      username: username,
      password: password,
      company_id: companyId,
      request_call: requestCall,
    })
    if(apiResponse.data.success){
      commit(t.SET_HOSTING_TRANSFER_INFO, {
        ...getters.hostingTransferInfo,
        provider,
        companyId,
        requestCall,
        id,
        username: null,
        password: null,
      })
    }

    return apiResponse
  },
  async completeHostingTransferRequest({ commit }, { companyId, domainName }) {
    const apiResponse = await http.put(`client/domains/hosting/hosting_transfers/${companyId}`,
      {
        complete_transfer: true,
        domain_name: domainName,
      })
    if(apiResponse.data.success) {
      commit(t.SET_HOSTING_TRANSFER_INFO, null)
    }

    return apiResponse
  },
  async deleteHostingTransferRequest({ commit }, { companyId }) {
    const apiResponse = await http.delete(`client/domains/hosting/hosting_transfers/${companyId}`)
    if (apiResponse.data?.status === 200) {
      commit(t.SET_HOSTING_TRANSFER_INFO, null)
    }

    return apiResponse
  },

  // Standalone hosting request-----------------------------------------------------------------------
  async fetchStandaloneHostingRequest({ commit }, { companyId }) {
    const apiResponse = await http.get(`client/domains/hosting/standalone_hosting/${companyId}`)
    if(apiResponse.data.success && apiResponse.data.result){
      const result = apiResponse.data.result
      commit(t.SET_STANDALONE_HOSTING_INFO, {
        id: result.id,
        companyId: result.company_id,
        domainName: result.domain,
        requestCall: result.request_call,
        status: result.status,
        admin: result.admin,
      })
    }

    return apiResponse
  },
  async submitStandaloneHostingRequest({ commit }, { companyId, domainName, requestCall }) {
    const apiResponse = await http.post(`client/domains/hosting/standalone_hosting`,
      {
        company_id: companyId,
        domain_name: domainName,
        request_call: requestCall,
      })
    if(apiResponse.data.success && apiResponse.data.response){
      const { response } = apiResponse.data
      commit(t.SET_STANDALONE_HOSTING_INFO, {
        id: response.id,
        companyId: response.company_id,
        domainName: response.domain,
        requestCall: response.request_call,
        status: response.status,
        admin: response.admin,
      })
    }

    return apiResponse
  },
  async updateStandaloneHostingRequest({ commit, getters }, { companyId, domainName, requestCall, id }) {
    const apiResponse = await http.put(`client/domains/hosting/standalone_hosting/${companyId}`,
      {
        company_id: companyId,
        domain_name: domainName,
        request_call: requestCall,
      })
    if(apiResponse.data.success){
      commit(t.SET_STANDALONE_HOSTING_INFO, {
        ...getters.standaloneHostingInfo,
        companyId,
        domainName,
        requestCall,
        id,
      })
    }

    return apiResponse
  },
  async completeStandaloneHostingRequest({ commit }, { companyId, domainName }) {
    const apiResponse = await http.put(`client/domains/hosting/standalone_hosting/${companyId}`,
      {
        complete_standalone_hosting: true,
        domain_name: domainName,
      })
    if(apiResponse.data.success) {
      commit(t.SET_STANDALONE_HOSTING_INFO, null)
    }

    return apiResponse
  },
  async deleteStandaloneHostingRequest({ commit }, { companyId }) {
    const apiResponse = await http.delete(`client/domains/hosting/standalone_hosting/${companyId}`)
    if (apiResponse.data?.status === 200) {
      commit(t.SET_STANDALONE_HOSTING_INFO, null)
    }

    return apiResponse
  },
}


const MUTATIONS = {
  [t.RESET_DOMAINS](state) {
    Object.assign(state, getDefaultState())
  },
  [t.SET_DOMAIN](state, domain) {
    state.domain = domain
  },
  [t.SET_DOMAIN_RECORDS](state, records) {
    records.sort((a, b) => {
      if (a.name < b.name)
        return -1
      if (a.name > b.name)
        return 1
      return 0
    })
    Vue.set(state.domain, 'records', records)
  },
  [t.SET_DOMAIN_LOCKED](state, locked) {
    state.domain.locked = locked
  },
  [t.SET_DOMAINS](state, domains) {
    state.domains = domains
  },
  [t.SET_HOSTING_TRANSFER_INFO](state, hostingTransferInfo) {
    state.hostingTransferInfo = hostingTransferInfo
  },
  [t.SET_STANDALONE_HOSTING_INFO](state, standaloneHostingInfo) {
    state.standaloneHostingInfo = standaloneHostingInfo
  },
  [t.SET_DOMAIN_TRANSFER_REQUESTS](state, domainTransferRequests) {
    state.domainTransferRequests = domainTransferRequests
  },
  [t.UPDATE_DOMAIN_TRANSFER_REQUEST](state, domainTransferRequest) {
    state.domainTransferRequests = [
      domainTransferRequest,
      ...state.domainTransferRequests.filter(dtr => dtr.id !== domainTransferRequest.id),
    ]
  },
  [t.SET_DOMAIN_WHOIS](state, domainWhois) {
    state.domainWhois = domainWhois
  },
  [t.SET_DOMAIN_SSL](state, domainSsl) {
    state.domainSsl = domainSsl
  },
  [t.SET_SELECTED_DOMAIN_RECORD](state, record) {
    state.selectedDomainRecord = record
  },
  [t.ADD_DOMAIN_RECORD](state, record) {
    Vue.set(state.domain.records, state.domain.records.length, record)
  },
  [t.UPDATE_DOMAIN_RECORD](state, record) {
    const recordIndex = state.domain.records.findIndex(rec => rec.id === record.id)
    Vue.set(state.domain.records, recordIndex, record)
  },
  [t.DELETE_DOMAIN_RECORD](state, record) {
    const records = state.domain.records.filter(rec => rec.id !== record.id)
    Vue.set(state.domain, 'records', records)
  },
  [t.SET_DOMAIN_NAMESERVERS](state, nameservers) {
    Vue.set(state.domain, 'nameservers', nameservers)
  },
  [t.SET_DOMAIN_INTERNET_NAMESERVERS](state, internetNameservers) {
    state.internetNameservers = internetNameservers
  },
  [t.SET_NAMESERVERS](state, nameservers) {
    state.nameservers = nameservers
  },
  [t.SET_DOMAIN_SEARCH](state, domainSearch) {
    state.domainSearch = domainSearch
  },
  [t.SET_DOMAIN_SEARCH_RESULTS](state, results) {
    state.domainSearchResults = results
  },
  [t.SET_SELECTED_DOMAIN](state, selectedDomain) {
    state.selectedDomain = selectedDomain
  },
  [t.SET_WEB_HOSTING](state, webHosting) {
    state.webHosting = webHosting
  },
  [t.SET_DOMAINS_FOR_ACCOUNT](state, domains) {
    state.domainsForAccount = domains
  },
  [t.SET_TLDS](state, tlds) {
    state.tlds = tlds
  },
  [t.SET_DOMAIN_AVAILABILITY](state, domainAvailability) {
    state.domainAvailability = domainAvailability
  },
  [t.SET_DOMAIN_AUTH_CODE](state, authCode) {
    Vue.set(state.domain, 'authCode', authCode)
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
